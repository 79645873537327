import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Justify } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';

import { useSelector } from 'react-redux';

import { ReactComponent as Logo } from '../../../assets/Logo.svg';

import LoggedNav from './LoggedNav';
import Button from '../../../components/button';

import { mobileNavLists } from 'src/constants';
import { ROUTE_DASHBOARD, ROUTE_EARLY_REGISTRATION, ROUTE_MARKET } from 'src/routes';

import { StyledLogoWrap } from './styled';
import ThemeToggle from './ThemeToggle';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { isDark } = useSelector(({ isDark }) => isDark);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const token = localStorage?.getItem('access');

  useEffect(() => {
    state?.status === 401 && localStorage.removeItem('token');
  }, [state]);

  useEffect(() => {
    setIsLoggedIn(!!token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      setIsLoggedIn(!!token);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-sm header-back justify-content-between p-0">
        <header
          className={`container-fluid ${isDark ? 'isDark' : ''}`}
          style={{ height: '15vh', padding: '0 30px' }}
        >
          <Link
            className="navbar-brand d-lg-flex d-none justify-content-left align-items-center pt-3 pb-3"
            to={'/'}
          >
            <Logo className="logo" />
          </Link>
          <button
            className="navbar-toggler d-flex d-lg-none"
            data-bs-toggle="modal"
            data-bs-target="#mobile-navbar"
          >
            <Justify width="40px" height="40px" />
          </button>
          <StyledLogoWrap>
            <Link
              className="navbar-brand d-flex d-lg-none justify-content-left align-items-center ms-4 pt-3 pb-3"
              to={'/'}
            >
              <Logo className="logo" />
            </Link>
          </StyledLogoWrap>
          <div className="d-flex d-lg-none me-3" style={{ alignItems: 'center' }}>
            {isLoggedIn ? (
              <LoggedNav />
            ) : (
              <Button text="Sign up" onClick={() => navigate(ROUTE_EARLY_REGISTRATION)} />
            )}
          </div>
          <div className="collapse navbar-collapse" id="mynavbar">
            <div
              className="col-md-6 d-lg-flex d-none justify-content-around align-items-center m-auto menu-group"
              style={{
                justifyContent: 'flex-start !important',
              }}
            >
              <Link className="link" to={ROUTE_MARKET}>
                Market
              </Link>
              <Link className="link" to="/trade?pair=btc-usdt">
                Trade
              </Link>
              {isLoggedIn && (
                <Link className="link" to={ROUTE_DASHBOARD}>
                  Dashboard
                </Link>
              )}
            </div>
            {isLoggedIn ? (
              <LoggedNav />
            ) : (
              <>
                <div className="col-md-4 d-lg-flex d-none justify-content-around align-items-center me-4">
                  <a href="#" className="header-account-font header-menu-btn">
                    ENG | USD
                  </a>
                  <ThemeToggle />
                  <Button text="Sign up" onClick={() => navigate(ROUTE_EARLY_REGISTRATION)} />
                </div>
              </>
            )}
          </div>
        </header>
      </nav>
      <div className="modal" id="mobile-navbar">
        <div className="modal-dialog">
          <div className="modal-content position-fixed start-0 top-0 mobile-navbar-content">
            <div className="modal-header mobile-navbar-header d-flex justify-content-start align-items-center">
              <Link to="/" onClick={() => navigate('/')} data-bs-dismiss="modal">
                <Image src="./assets/logo.png" width="50%" />
              </Link>
            </div>
            <div className="modal-body p-0">
              {mobileNavLists.map(({ link, title, Icon }, index) => (
                <Link
                  to={link}
                  key={index}
                  className={`mobile-nav-link ${
                    isLoggedIn || title === 'Market' || title === 'Trade' ? 'd-flex' : 'd-none'
                  } align-items-center ${index === 0 && 'mt-5'}`}
                  onClick={() => navigate(link)}
                  data-bs-dismiss="modal"
                >
                  <Icon />
                  <div className="ms-2">{title}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
